import simpleParallax from 'simple-parallax-js';
import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


export default class {
	constructor() {
		this.parallax();
		this.parallaxService();
	}
	parallax(){
		//var image = document.getElementsByClassName('p-home-vision');
		//new simpleParallax(image);
		var image = document.getElementsByClassName('p-home-vision');
		if( image !== null ){
			new simpleParallax(image, {
				orientation: 'up',
				scale: 1.2,
				overflow: true,
				delay: 0,
				transition: 'cubic-bezier(0, 0.01, 0.56, 1.22)',
			});
		}
	}

	parallaxService() {
		const secService = document.querySelector('.js-parallax-service');
		if (secService) {
			gsap.set(secService,{
				y: '-10%',
			});
			gsap.to(secService, {
				y: 0,
				scrollTrigger:{
					duration:10,
					ease: "power2.out",
					trigger: secService,
					start: 'top center',
					end: 'top+=25% center',
					scrub: true,
				}
			});
		}
	}
}