import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class {
	constructor() {
		this.fadeInImg();
		this.fadeInItem();
	}
	fadeInImg() {
		const fadeImgs = gsap.utils.toArray('.js-fadein-img');
		fadeImgs.forEach((fadeImg) => {
			ScrollTrigger.create({
				//markers: true,
				trigger: fadeImg, //アニメーションが始まるトリガーとなる要素
				start: 'top 75%',
				end: 'bottom 75%',
				toggleClass: {
					targets: fadeImg,
					className: "is-active"
				},
				once: true
			});
		});
	}
	fadeInItem() {
		const fadeItems = gsap.utils.toArray('.js-fadein-item');
		fadeItems.forEach((fadeItem) => {
			ScrollTrigger.create({
				//markers: true,
				trigger: fadeItem, //アニメーションが始まるトリガーとなる要素
				start: 'top 75%',
				end: 'bottom 75%',
				toggleClass: {
					targets: fadeItem,
					className: "is-active"
				},
				once: true
			});
		});
	}
}