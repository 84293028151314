export default class {
    constructor() {
        this.copyUrl();
    }
    copyUrl() {
			const urlCopyBtn = document.querySelector('.js-url-copy');
			if (urlCopyBtn) {
				urlCopyBtn.addEventListener('click', () => {
					const copyUrl = urlCopyBtn.dataset.url;
					navigator.clipboard.writeText(copyUrl);
					//console.log('ok');
					//console.log(copyUrl);
				});
			}
    }
}