import MicroModal from 'micromodal';

export default class {
    constructor() {
        this.telInput = document.querySelector('.js-tel-input');
        this.init();
    }
    init() {
        const telInput = this.telInput;
        if (telInput) {
            telInput.setAttribute('inputmode','tel');
        }

    }
}