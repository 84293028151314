import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default class {
    constructor() {

        this.opened = false
        this.body = document.body;

        const bodyScrollLock = require('body-scroll-lock');
        // スクロールをロックする処理
        this.disableBodyScroll = bodyScrollLock.disableBodyScroll;
        // ロックを解除する処理
        this.enableBodyScroll = bodyScrollLock.enableBodyScroll;
        // オプション
        this.options = {
            /**
             * bodyにスクロールバー分のpadding-leftを追加するか
             * default: false
             */
            reserveScrollBarGap: true,
        }

        /**
         * Hamburger
         */
        this.drawer_id = "#js-drawer";
        this.hamburger_id = "#js-drawer-hamburger";
        this.openClass = "is-open";
        this.init();

        /**
         * Hamburger
         */
        this.drawerItemLink = "#js-drawer a";
        this.currentPage()


        this.fixHeader();
    }
    currentPage() {
        const drawerItemLinks = document.querySelectorAll(this.drawerItemLink);
        drawerItemLinks.forEach((el) => {
            el.addEventListener('click', (e) => {
                this.close();
            })
        })
    }

    init() {
        this.drawer = document.querySelector(this.drawer_id);
        this.hamburger = document.querySelector(this.hamburger_id);
        if (this.drawer) {
            if (this.hamburger) {
                this.hamburger.addEventListener('click', (e) => {
                    this.drawerClick(e);
                })
            }
        }
    }
    drawerClick(e) {
        this.drawerToggleClass();
    }
    drawerToggleClass() {
        if (!this.opened) {
            this.open();
        } else {
            this.close();
        }
    }
    open() {
        // this.disableBodyScroll(this.body, this.options);
        this.drawer.style.display = 'block';
        setTimeout(() => {
            //this.drawer.setAttribute('aria-hidden', false);
            this.drawer.classList.add(this.openClass);
            this.hamburger.classList.add(this.openClass);
            //this.hamburger.setAttribute('aria-expanded', true);
            //this.hamburger.setAttribute('aria-label', 'メニューを閉じる');

            this.opened = true;
        }, 100);
    }
    close() {
        // this.enableBodyScroll(this.body, this.options);

        //this.drawer.setAttribute('aria-hidden', true);
        this.drawer.classList.remove(this.openClass);
        this.hamburger.classList.remove(this.openClass);
        //this.hamburger.setAttribute('aria-expanded', false);
        //this.hamburger.setAttribute('aria-label', 'メニューを開く');

        this.opened = false;
        setTimeout(() => {
            this.drawer.style.display = 'none';
        }, 100)
    }
    fixHeader() {
        //MV fix_header
        const fixCont = document.querySelector('.js-header');
        if( fixCont !== null ){
        const addClassOverMv = () => {
            const elem = document.querySelectorAll('.js-header');
            const height = window.innerHeight;
            const scrollY = window.pageYOffset;
            if(elem.length > 0) {
                if ( scrollY > height ) {
                    elem.forEach((el, i) => {
                        el.classList.add('is-active');
                    })
                } else {
                    elem.forEach((el, i) => {
                        el.classList.remove('is-active');
                    })
                }
            }
        }
        window.addEventListener('scroll', () => {
            addClassOverMv();
        })
        }
    }
}