import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class {
    constructor() {
			//this.headerAnimation();
			this.mvTxtAnimation();
			this.imgExpansion();
    }
    mvTxtAnimation() {
			const jsMv = document.querySelector('.js-mv');
			const jsHeader = document.querySelector('.js-header');
			const mvArea = document.querySelector('.js-mv');
			const mvJa = document.querySelector('.js-mv__ja');
			const mvEn = document.querySelector('.js-mv__en');
			const mvEnTxt01 = document.querySelector('.js-mv__en01');
			const mvEnTxt02 = document.querySelector('.js-mv__en02');

			if(jsMv !== null){
				gsap.set(jsHeader,{
					y: '-100%',
				});
				gsap.set(mvEnTxt01,{
					x: '-25%',
					autoAlpha: 0,
				});
				gsap.set(mvEnTxt02,{
					autoAlpha: 0,
					x: '25%',
				});
				gsap.set(mvJa,{
					scale: 1.5,
				});
				gsap.to(jsHeader,{
					ease: "power2.inOut",
					duration: 0.8,
					delay: 0.5,
					y: 0,
				});

				const mvTxtAnimeJa = gsap.timeline({
					scrollTrigger:{
						//markers: true,
						trigger: mvArea,
						start: 'top top',
						endTrigger: mvEn,
						end: 'top bottom-=120px',
						scrub: true,
						pin: true,
					}
				});
				mvTxtAnimeJa.to(mvJa,{
					scale: 1,
					duration: 0.5,
				});

				const mvTxtAnimeEn = gsap.timeline({
					scrollTrigger:{
						//markers: true,
						trigger: mvEn,
						start: 'top bottom-=120px',
					}
				});
				mvTxtAnimeEn.to(
					mvEnTxt01,{
						x: 0,
						autoAlpha: 1,
						duration: 2,
						ease: "power1.out",
					}
				)
				.to(
					mvEnTxt02,{
						x: 0,
						autoAlpha: 1,
						duration: 2,
						delay: 0.1,
						ease: "power1.out",
					},"<0.3"
				);
			}
		}

    imgExpansion() {
			const imgTarget = document.querySelector('.js-animation-expansion');
			if( imgTarget !== null ){
				const expansionImg = gsap.timeline({
					scrollTrigger: {
						//markers: true,
						trigger: imgTarget,
						start: "top 85%",
						end: "bottom+=150% bottom",
						scrub: 0.1,
						toggleClass: {
							targets: ".js-vision",
							className: "is-active"
						},
					},
				});
				const bgChange = gsap.timeline({
					scrollTrigger: {
						//markers: true,
						trigger: '.js-vision',
						start: "top center",
						end: "bottom bottom",
					},
				});
				expansionImg.fromTo(imgTarget,{
					transformOrigin: 'top center',
					scale: 0.4,
				},{
					scale: 1,
				});
				bgChange.fromTo('.js-vision',{
					backgroundColor: '#fff',
				},{
					duration: '0.9',
					backgroundColor: '#1D1D21',
				});
			}
		}
}
