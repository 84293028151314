import Swiper from'swiper/bundle';
import 'swiper/css/bundle';

export default class {
    constructor() {
        this.blogSlider();
    }

    blogSlider(){
        const blogSlider = '.js-blog-slider';
        new Swiper (blogSlider,{
            effect:'slide',
            speed:1000,
            easing: "easeOutExpo",
            // autoplay: {
            //     delay: 4000
            // },
            slidesPerView: 1.1,
            spaceBetween: 20,
            breakpoints: {
                1280: {
                  slidesPerView: 2.5,
                  spaceBetween: 35
                },
            },
            pagination: {
                el: ".js-blog-slider-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".js-blog-slider__next",
                prevEl: ".js-blog-slider__prev"
            },
            scrollbar: {
                el: '.js-blog-scrollbar',
            },
        });
    }
}