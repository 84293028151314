import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class {
	constructor() {
		this.following();
	}
	following() {
		const jsFollowing = document.querySelector('.js-following');
		if( jsFollowing !== null ){
			const FollowingTl = gsap.timeline({
				scrollTrigger:{
					//markers: true,
					trigger: '.js-following',
					start: 'top top',
					endTrigger: '.js-following-area',
					end: 'bottom bottom',
					toggleClass: {
						targets: '.js-following',
						className: "is-active"
					},
				}
			});
		}
	}
}