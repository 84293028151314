import "../scss/style.scss";

/**
 * @babel/polyfill の利用
 * ※ PromiseやArray.includes()など、構文ではない機能的な仕様を利用するために必要
 */
import "@babel/polyfill";

import lazysizes from "lazysizes"
import unveil from "lazysizes/plugins/unveilhooks/ls.unveilhooks.min.js"

// if ('NodeList' in window && !NodeList.prototype.forEach) {
//     NodeList.prototype.forEach = function (callback, thisArg) {
//         thisArg = thisArg || window;
//         for (var i = 0; i < this.length; i++) {
//             callback.call(thisArg, this[i], i, this);
//         }
//     };
// }

import Slider from "./lib/Slider";
import Mv from "./lib/Mv";
import Fadein from "./lib/Fadein";
import Following from "./lib/Following";
import Parallax from "./lib/Parallax";
import ResponsiveImages from "./lib/ResponsiveImages";
import ScrollTrigger from "./lib/ScrollTrigger";
import Urlcopy from "./lib/Urlcopy";
import Drawer from "./lib/Drawer";
import From from "./lib/Form";

new Slider();
new Mv();
new Fadein();
new Following();
new Parallax();
new ResponsiveImages();
new ScrollTrigger();
new Urlcopy();
new Drawer();
new From();